{
  "auth": {
    "agree": "I agree to",
    "createAccount": "Create account",
    "createNewAccount": "Create New Account",
    "email": "Email",
    "login": "Login",
    "password": "Password",
    "forgotPassword": "Forgot password?",
    "recover_password": "Recover password",
    "recovery_message": "Enter your email address and we will send you a link to reset your password.",
    "sign_up": "Sign Up",
    "keep_logged_in": "Keep me logged in",
    "termsOfUse": "Terms of Use.",
    "reset_password": "Reset password",
    "update_password": "Update password"
  },
  "404": {
    "title": "This page’s gone fishing.",
    "text": "If you feel that it’s not right, please send us a message at ",
    "back_button": "Back to dashboard"
  },
  "typography": {
    "primary": "Primary text styles",
    "secondary": "Secondary text styles"
  },
  "dashboard": {
    "versions": "Versions",
    "setupRemoteConnections": "Setup Remote Connections",
    "currentVisitors": "Current Visitors",
    "navigationLayout": "navigation layout",
    "topBarButton": "Top Bar",
    "sideBarButton": "Side Bar"
  },
  "language": {
    "brazilian_portuguese": "Português",
    "english": "English",
    "spanish": "Spanish",
    "simplified_chinese": "Simplified Chinese",
    "persian": "Persian"
  },
  "menu": {
    "auth": "Auth",
    "buttons": "Buttons",
    "timelines": "Timelines",
    "dashboard": "Dashboard",
    "billing": "Billing",
    "login": "Login",
    "preferences": "Account preferences",
    "payments": "Payments",
    "settings": "Application settings",
    "pricing-plans": "Pricing plans",
    "payment-methods": "Payment methods",
    "signup": "Signup",
    "recover-password": "Recover password",
    "recover-password-email": "Recover password email",
    "404": "404",
    "faq": "FAQ",
    "users": "Users",
    "projects": "Projects",
    "faults": "Faults",
    "battery": "Battery",
    "home": "Home",
    "profile": "Profile",
    "dashboard-settings": "Dashboard settings",
    "scheduler": "Scheduler"
  },
  "messages": {
    "all": "See all messages",
    "new": "New messages from {name}",
    "mark_as_read": "Mark As Read"
  },
  "navbar": {
    "messageUs": "Web development inquiries:",
    "repository": "GitHub Repo"
  },
  "notifications": {
    "all": "See all notifications",
    "less": "See less notifications",
    "mark_as_read": "Mark as read",
    "sentMessage": "sent you a message",
    "uploadedZip": "uploaded a new Zip file with {type}",
    "startedTopic": "started a new topic"
  },
  "user": {
    "language": "Change language",
    "logout": "Logout",
    "profile": "Profile",
    "settings": "Settings",
    "billing": "Billing",
    "faq": "FAQ",
    "helpAndSupport": "Help & support",
    "projects": "Projects",
    "account": "Account",
    "explore": "Explore"
  },
  "treeView": {
    "basic": "Basic",
    "icons": "Icons",
    "selectable": "Selectable",
    "editable": "Editable",
    "advanced": "Advanced"
  },
  "chat": {
    "title": "Chat",
    "sendButton": "Send"
  },
  "spacingPlayground": {
    "value": "Value",
    "margin": "Margin",
    "padding": "Padding"
  },
  "spacing": {
    "title": "Spacing"
  },
  "cards": {
    "cards": "Cards",
    "fixed": "Fixed",
    "floating": "Floating",
    "contentText": "The unique stripes of zebras make them one of the animals most familiar to people.",
    "contentTextLong": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. Various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction. Grévy's zebra and the mountain zebra are endangered. While plains zebras are much more plentiful, one subspecies, the quagga.",
    "rowHeight": "Row height",
    "title": {
      "default": "Default",
      "withControls": "With controls",
      "customHeader": "Custom header",
      "withoutHeader": "Without header",
      "withImage": "With Image",
      "withTitleOnImage": "With title on image",
      "withCustomTitleOnImage": "With custom title on image",
      "withStripe": "With stripe",
      "withBackground": "With background"
    },
    "button": {
      "main": "Main",
      "cancel": "Cancel",
      "showMore": "Show More",
      "readMore": "Show More"
    },
    "link": {
      "edit": "Edit",
      "setAsDefault": "Set as default",
      "delete": "Delete",
      "traveling": "Traveling",
      "france": "France",
      "review": "Review",
      "feedback": "Leave feedback",
      "readFull": "Read full article",
      "secondaryAction": "Secondary action",
      "action1": "Action 1",
      "action2": "Action 2"
    }
  },
  "colors": {
    "themeColors": "Theme Colors",
    "extraColors": "Extra Colors",
    "gradients": {
      "basic": {
        "title": "Button Gradients"
      },
      "hovered": {
        "title": "Hovered Button Gradients",
        "text": "Lighten 15% applied to an original style (gradient or flat color) for hover state."
      },
      "pressed": {
        "title": "Pressed Button Gradients",
        "text": "Darken 15% applied to an original style (gradient or flat color) for pressed state."
      }
    }
  },
  "tabs": {
    "alignment": "Tabs alignment",
    "overflow": "Tabs overflow",
    "hidden": "Tabs with hidden slider",
    "grow": "Tabs grow"
  },
  "helpAndSupport": "Help & support",
  "aboutVuesticAdmin": "About Vuestic Admin",
  "search": {
    "placeholder": "Search..."
  },
  "buttonSelect": {
    "dark": "Dark",
    "light": "Light"
  },
  "custom": {
    "language": "Language",
    "turn_off_inverter": "Turn off system",
    "turn_on_inverter": "Turn on system",
    "regulation_active": "Turn off regulation",
    "regulation_inactive": "Turn on regulation",
    "min_max_regulation_active": "Turn off Max/Min regulation",
    "min_max_regulation_inactive": "Turn on Max/Min regulation",
    "emergency_stop": "Emergency stop",
    "force_battery_charge": "Forced battery charging",
    "set_charge": "Set charge",
    "unset_charge": "Unset charge",
    "actual_regulation_state": "Current regulation state",
    "actual_charge_state": "Current charge state",
    "actual_regulation_value": "Current regulation value",
    "actual_calculated_regulation": "Current calculated regulation",
    "actual_regulation_Max_Min_state": "Max/Min regulation state",
    "actual_regulation_Max_Min_value": "Max/Min regulation value",
    "total": "Total",
    "total_active_power": "Total active power",
    "l1_active_power": "L1 active power",
    "l2_active_power": "L2 active power",
    "l3_active_power": "L3 active power",
    "l1_voltage": "L1 voltage",
    "l2_voltage": "L2 voltage",
    "l3_voltage": "L3 voltage",
    "pt": "PT",
    "pa": "PA",
    "pb": "PB",
    "pc": "PC",
    "p1_current": "Phase 1 current",
    "p2_current": "Phase 2 current",
    "p3_current": "Phase 3 current",
    "battery_1_soc": "Battery 1 SOC",
    "battery_2_soc": "Battery 2 SOC",
    "battery_3_soc": "Battery 3 SOC",
    "battery_4_soc": "Battery 4 SOC",
    "battery_5_soc": "Battery 5 SOC",
    "battery_6_soc": "Battery 6 SOC",
    "charts": "Charts",
    "compound": "Compound",
    "current_per_phase": "Current per phase",
    "total_active_power_chart": "Total active power",
    "regulation_chart": "Regulation",
    "inverter_faults": "Inverter faults",
    "module_temperature": "Module temperature",
    "ambient_temperature": "Ambient temperature",
    "logout": "Logout",
    "last_update": "Last update",
    "loading_data": "Loading data",
    "page_not_found": "Page not found",
    "dont_have_account": "Don't have an account?",
    "sign_up": "Sign up",
    "password": "Password",
    "repeat_password": "Repeat password",
    "create_account": "Create account",
    "login": "Log in",
    "already_have": "Already have an account?",
    "password_hint": "Password should be more than 8 characters: letters, at least one number, and a special character.",
    "field_required": "Field is required",
    "passwords_not_match": "Passwords do not match",
    "password_min_length": "Password should be more than 8 characters",
    "password_one_letter": "Password should contain at least one letter",
    "password_one_number": "Password should contain at least one number",
    "password_one_special": "Password should contain at least one special character",
    "email_invalid": "Email is not valid",
    "unsaved_changes_form": "You have unsaved changes. Are you sure you want to leave this page?",
    "add_user": "Add user",
    "edit_user": "Edit user",
    "add_irebox": "Add iRebox",
    "add": "Add",
    "edit": "Edit",
    "save": "Save",
    "cancel": "Cancel",
    "delete": "Delete",
    "is_active": "Active",
    "is_verified": "Verified",
    "is_superuser": "Administrator",
    "is_dashboard_only": "Dashboard only",
    "ireboxes": "iReboxes",
    "hide": "Close",
    "more_info": "More info",
    "delete_irebox": "Delete iRebox",
    "delete_user": "Delete user",
    "delete_irebox_confirmation": "Are you sure you want to delete iRebox {irebox} of user {user}?",
    "delete_user_confirmation": "Are you sure you want to delete user {user}?",
    "cannot_delete_yourself": "You cannot delete yourself",
    "bms_data": "Battery management system data",
    "battery_charging": "Battery is charging",
    "battery_discharging": "Battery is discharging",
    "work_in_progress": "Work in progress",
    "battery_charge_total": "Total battery charge",
    "bms_soc_cumulative_chart": "Battery state of charge",
    "smart_meter_cumulative_chart": "Total power and power per phase",
    "total_active_power_cumulative_chart": "Total active power",
    "solar_power_cumulative_chart": "Solar power",
    "daily": "Day",
    "weekly": "Week",
    "monthly": "Month",
    "devices": "Devices",
    "search": "Search",
    "no_devices": "You have no devices",
    "user_info": "User info",
    "edit_password": "Edit password",
    "old_password": "Old password",
    "new_password": "New password",
    "confirm_password": "Confirm password",
    "irebox_info": "iRebox info",
    "add_irebox_alias": "Add iRebox alias",
    "delete_irebox_alias": "Delete iRebox alias",
    "delete_irebox_alias_confirmation": "Are you sure you want to delete iRebox alias for {name}?",
    "back": "Back",
    "input_integer": "Enter an integer",
    "inputKW_invalid": "Enter a valid value in kW within the range of -500 to 500",
    "inputSoc_invalid": "Enter a valid value in % within the range of 0 to 100",
    "download": "Download",
    "download_format": "Download in format",
    "custom": "Custom",
    "from": "From",
    "to": "To",
    "set": "Set",
    "select_time_interval": "Select time interval",
    "error_from_greater_than_to": "From date should be less than to date",
    "bucket_size": "Bucket size",
    "mode": "Mode",
    "smart_management": "Smart management",
    "conditioned_charging": "Conditioning charging",
    "next_planned_conditioned_charging": "Next planned conditioning charging",
    "last_full_charge": "Last full charge",
    "max_charge": "Maximum charging",
    "max_discharge": "Maximum discharging",
    "idle": "Planned",
    "running": "Running",
    "converter_power": "Converter power",
    "start_datetime": "Start date and time",
    "end_datetime": "End date and time",
    "turn_off": "Turn off",
    "turn_on": "Turn on",
    "current_state": "Current state",
    "status_overview": "Status overview",
    "device_status": "Device status",
    "main_inverter_type": "Main inverter type",
    "no_issues_detected": "No issues detected",
    "issues_detected": "Issues detected - support has been notified",
    "name": "Name"
  },
  "control_mode": {
    "title": "Control mode",
    "disabled": "Disabled",
    "disabled_discharge": "Disabled discharge",
    "auto": "Auto",
    "forced_charge": "Manual battery charge",
    "forced_discharge": "Manual battery  discharge",
    "min_charge": "Minimum battery charge",
    "max_charge": "Maximum battery charge",
    "inverter_power": "Inverter power",
    "regulation": "Regulation",
    "regulation_power": "Regulation power",
    "min_max_regulation": "Min/Max regulation",
    "min_regulation_power": "Minimum regulation power",
    "max_regulation_power": "Maximum regulation power",
    "mode": "Mode",
    "min_less_than_max": "Minimum value should be less than maximum value"
  },
  "scheduler": {
    "new_plan": "New plan",
    "create_plan": "Create plan",
    "plan_details": "Plan details",
    "event_overlaps": "Event overlaps with existing event",
    "start": "Date and time of start",
    "end": "Date and time of end",
    "params": "Parameters",
    "event_updated": "Event updated",
    "event_update_error": "Failed to update event"
  },
  "management_picker": {
    "selected_mode": "Selected mode",
    "manual": "Manual",
    "scheduler": "Scheduler"
  },
  "error_messages": {
    "fetch_users": "Failed to load users",
    "modify_user": "Failed to modify user, error code: {error}"
  }
}
