<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaInnerLoading v-if="loading" v-model:loading="loading" color="black">
      <div class="flex flex-col items-center justify-center h-[600px]">
        <p class="text-lg mt-20">{{ t('custom.loading_data') }}...</p>
      </div>
    </VaInnerLoading>
    <VaAccordion v-else multiple class="pt-2">
      <div v-for="(route, index) in routeFiltered2" :key="index">
        <VaSidebarItem
          :to="route.children ? undefined : { name: route.name }"
          :active-color="activeColor"
          :text-color="textColor(route)"
          role="button"
          hover-opacity="0.10"
        >
          <VaSidebarItemContent class="py-3 pr-2 pl-4">
            <VaIcon
              v-if="route.meta.icon"
              aria-hidden="true"
              :name="route.meta.icon"
              size="20px"
              :color="iconColor(route)"
            />
            <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
              {{ t(route.displayName) }}
            </VaSidebarItemTitle>
          </VaSidebarItemContent>
        </VaSidebarItem>
      </div>

      <!--  FILTER SECTION    -->
      <div class="p-5 pb-0 pr-3 flex justify-between">
        <div class="flex items-center">
          <VaListLabel class="va-text-sm va-text-left h-full">{{ t('custom.devices') }}</VaListLabel>
        </div>
        <VaIcon
          class="material-icons rounded items-center flex justify-center transition ease-in-out duration-300 w-[1.5rem] !h-[1.5rem]"
          :class="
            searchVisible
              ? ' bg-blue-100 rounded items-center flex justify-center transition ease-in-out duration-300 hover:bg-blue-200 hover:transition-all hover:ease-in-out hover:duration-300'
              : ''
          "
          :color="searchVisible ? 'primary' : 'secondary'"
          @click="toggleSearch()"
          >filter_alt</VaIcon
        >
      </div>

      <Accordion :is-open="searchVisible">
        <Transition name="slide-fade">
          <VaInput
            v-if="searchVisible"
            v-model="searchQuery"
            :placeholder="t('custom.search') + '...'"
            class="mx-4 my-2 w-fit shadow"
          />
        </Transition>
      </Accordion>

      <!--  DEVICE SECTION    -->
      <VaCollapse v-for="(device, index3) in filteredDevices" :key="index3">
        <template #header="{ value: isCollapsed }">
          <VaSidebarItem
            :active="isActiveDevice(device.irebox)"
            :active-color="activeColor"
            :text-color="textColorDevice(device.irebox)"
          >
            <VaSidebarItemContent class="py-3 pr-2 pl-4">
              <VaIcon class="material-icons">battery_charging_full</VaIcon>
              <VaSidebarItemTitle
                class="flex justify-between items-center leading-5 font-semibold"
                :class="device.irebox_name !== device.irebox ? '!text-[0.8rem]' : '!text-[0.6rem]'"
              >
                {{ device.irebox_alias ?? device.irebox_name }}
                <div>
                  <VaPopover
                    :message="
                      'iRebox id: ' +
                      device.irebox +
                      (device.irebox_alias ? ` , iRebox name: ${device.irebox_name}` : '')
                    "
                    auto-placement
                  >
                    <VaIcon v-if="device.irebox_name !== device.irebox" class="material-icons pr-2 text-gray-500"
                      >info</VaIcon
                    >
                  </VaPopover>
                  <VaIcon :name="arrowDirection(isCollapsed)" size="20px" />
                </div>
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <template #body>
          <template v-for="(route, index4) in routeFiltered">
            <VaSidebarItem
              v-if="isInverterCompatible(device, route.name)"
              :key="index4"
              :to="{ name: route.name, params: { uuid: device.irebox } }"
              :active-color="activeColor"
              :text-color="textColorDevice(device.irebox, route.name)"
              :aria-label="`Visit ${route}`"
              hover-opacity="0.20"
              @click="setStoreValue(device)"
            >
              <VaSidebarItemContent v-if="isInverterCompatible(device, route.name)" class="py-3 pr-2 pl-8">
                <VaIcon
                  v-if="route.meta.icon"
                  aria-hidden="true"
                  :name="route.meta.icon"
                  :color="iconColorDevice(device.irebox, route.name)"
                />
                <VaSidebarItemTitle class="leading font-semibold text-[0.8rem]">
                  {{ t(route.displayName) }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </template>
        </template>
      </VaCollapse>
      <VaSidebarItem v-if="filteredDevices.length === 0" class="py-3 pl-6 font-bold !text-gray-500" readonly disabled>
        <VaIcon class="material-icons pr-2">info</VaIcon>
        {{ t('custom.no_devices') }}
      </VaSidebarItem>
    </VaAccordion>

    <!--  FOOTER SECTION    -->
    <VaSpacer></VaSpacer>
    <div class="flex justify-between pr-4 pl-4 pb-4 flex-row items-center">
      <VaIcon
        class="material-icons m-1 bg-blue-100 shadow-2xl rounded-3xl border-2 border-gray-300 w-[2rem] !h-[2rem] items-center flex justify-center transition ease-in-out duration-300 hover:bg-blue-200 hover:transition-all hover:ease-in-out hover:duration-300"
        size="large"
        :class="isRouteActiveByName('profile') ? 'border-blue-600' : ''"
        :color="isRouteActiveByName('profile') ? 'primary' : 'secondary'"
        @click="toProfile()"
        >person</VaIcon
      >
      <VaButton @click="logout">
        {{ t('custom.logout') }}
      </VaButton>
    </div>
  </VaSidebar>
</template>
<script lang="ts">
import { defineComponent, watch, ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'

import { useI18n } from 'vue-i18n'
import { useColors } from 'vuestic-ui'

import navigationRoutes, { type INavigationRoute } from './NavigationRoutes'
import { useAuthStore } from '../../stores/auth'
import { get_devices } from '../../axios'
import Accordion from './Accordion.vue'
import router from '../../router'
import { useGlobalStore } from '../../stores/global-store'

export default defineComponent({
  name: 'Sidebar',
  components: { Accordion },
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false },
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    interface IDevice {
      irebox: string
      inverter_type: string
      irebox_alias?: string
      irebox_name?: string
      scheduler: boolean
    }

    const { getColor, colorToRgba } = useColors()
    const route = useRoute()
    const { t } = useI18n()
    const authStore = useAuthStore()
    const globalStore = useGlobalStore()
    const loading = ref(true)
    const mounted = ref(false)
    const devices = ref<IDevice[]>([])
    const devicesFiltered = ref<IDevice[]>([])
    const searchVisible = ref(false)
    const searchQuery = ref('')
    const value = ref<boolean[]>([])

    const toggleSearch = () => {
      searchVisible.value = !searchVisible.value
    }

    watch(searchVisible, (val) => {
      if (!val) {
        searchQuery.value = ''
      }
    })

    watch(
      searchQuery,
      debounce((val) => {
        if (val) {
          const fuse = new Fuse<IDevice>(devices.value, {
            includeScore: true,
            threshold: 0.3,
            keys: ['irebox', 'alias'],
          })

          // search device by irebox
          const result = fuse.search(val)
          devicesFiltered.value = result.map((r: any) => r.item)
        }
      }, 300),
    )

    interface inverterRoutes {
      [key: string]: string[]
    }

    const setStoreValue = (device: IDevice) => {
      globalStore.setIreboxUUID(device.irebox)
      globalStore.setInverterType(device.inverter_type)
    }

    // Add routes here
    const routes = ['dashboard', 'faults', 'battery', 'scheduler']

    // inverter types will display only these routes (routes are super set of inverter routes)
    const deviceInverterRoutes = {
      sinexcel: ['dashboard', 'faults', 'battery'],
      deye: ['dashboard'],
    } as inverterRoutes

    const isInverterCompatible = (device: IDevice, routeName: string) => {
      return getInverterRoutes(device).includes(routeName)
    }

    const getInverterRoutes = (device: IDevice) => {
      // check if scheduler is enabled and add it
      const ret = [...deviceInverterRoutes[device.inverter_type]] ?? []
      if (device.scheduler) {
        ret.push('scheduler')
      }
      return ret
    }

    const filteredDevices = computed(() => {
      return searchQuery.value ? devicesFiltered.value : devices.value
    })

    const routeFiltered = computed(() => {
      return filterNavigationRoutes(navigationRoutes.routes).filter((route) => routes.some((r) => r === route.name))
    })

    const routeFiltered2 = computed(() => {
      const routesFiltered = filterNavigationRoutes(navigationRoutes.routes)

      // return only those that are not in routes
      return routesFiltered.filter((route) => !routes.some((r) => r === route.name) && route.meta.displaySidebar)
    })

    onMounted(() => {
      mounted.value = true
    })
    onBeforeUnmount(() => {
      mounted.value = false
    })

    watch(
      () => mounted.value,
      async () => {
        const ret = await get_devices()
        devices.value = ret.data
        loading.value = false
      },
    )

    const filterNavigationRoutes = (navigationRoutes: INavigationRoute[]) => {
      return navigationRoutes.filter((route) => {
        // add read only access to the sidebar
        let ret = !route.meta.requiresSuperuser || authStore.isSuperUser()
        if (route.meta.requiresReadOnly) {
          ret = ret || authStore.isReadOnly()
        }
        return ret
      })
    }

    const logout = () => {
      authStore.logout()
    }

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v),
    })

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name
    const isActiveDevice = (device: string) => route.path.endsWith(`${device}`)

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`)
      }

      return section.children.some(({ name }) => route.path.endsWith(`${name}`))
    }

    const routeIsActive = (route: INavigationRoute) => {
      if (route.children) {
        return route.children.some((child) => isActiveChildRoute(child))
      }

      return isActiveChildRoute(route)
    }

    const isRouteActiveByName = (routeName: string) => route.name === routeName

    const setActiveExpand = () =>
      (value.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route)))

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
    const color = computed(() => getColor('background-secondary'))
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
    const iconColorDevice = (device: string, route_name?: string) => {
      if (route_name) {
        return isActiveDevice(device) && route_name === route.name ? 'primary' : 'secondary'
      }
      return isActiveDevice(device) ? 'primary' : 'secondary'
    }

    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
    const textColorDevice = (device: string, route_name?: string) => {
      if (route_name) {
        return isActiveDevice(device) && route_name === route.name ? 'primary' : 'textPrimary'
      }
      return isActiveDevice(device) ? 'primary' : 'textPrimary'
    }
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

    watch(() => route.fullPath, setActiveExpand, { immediate: true })

    const toProfile = () => {
      router.push({ name: 'profile' })
    }

    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      isActiveDevice,
      t,
      iconColor,
      textColor,
      arrowDirection,
      logout,
      filterNavigationRoutes,
      loading,
      devices,
      routeFiltered,
      routeFiltered2,
      textColorDevice,
      routeIsActive,
      iconColorDevice,
      searchVisible,
      searchQuery,
      toggleSearch,
      filteredDevices,
      toProfile,
      isRouteActiveByName,
      isInverterCompatible,
      setStoreValue,
    }
  },
})
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
